/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'universal-access-circle': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 4.143A1.071 1.071 0 108 2a1.071 1.071 0 000 2.143m-4.668 1.47l3.24.316v2.5l-.323 4.585A.383.383 0 007 13.14l.826-4.017c.045-.18.301-.18.346 0L9 13.139a.383.383 0 00.752-.125L9.43 8.43v-2.5l3.239-.316a.38.38 0 00-.047-.756H3.379a.38.38 0 00-.047.756z"/><path pid="1" d="M8 0a8 8 0 100 16A8 8 0 008 0M1 8a7 7 0 1114 0A7 7 0 011 8"/>',
    },
});
